import { Fragment } from "react";
import type { ToastOptions } from "react-hot-toast";
import { X } from "lucide-react";
import { Toaster, toast } from "react-hot-toast";

export const notification = {
  error: (
    message: string,
    {
      closable = true,
      ...toastOptions
    }: { closable?: boolean } & ToastOptions = {},
  ) =>
    toast.error(
      (t) => (
        <Fragment>
          {message}
          {closable ? (
            <button
              type="button"
              className="ml-4 inline-flex rounded-md text-white text-opacity-90 transition-colors hover:text-opacity-100"
              onClick={() => toast.dismiss(t.id)}
            >
              <X className="h-5 w-5" aria-label="Dismiss" />
            </button>
          ) : null}
        </Fragment>
      ),
      toastOptions,
    ),
  info: (
    message: string,
    {
      closable = true,
      ...toastOptions
    }: { closable?: boolean } & ToastOptions = {},
  ) =>
    toast(
      (t) => (
        <Fragment>
          {message}
          {closable ? (
            <button
              type="button"
              className="ml-4 inline-flex rounded-md text-white text-opacity-90 transition-colors hover:text-opacity-100"
              onClick={() => toast.dismiss(t.id)}
            >
              <X className="h-5 w-5" aria-label="Dismiss" />
            </button>
          ) : null}
        </Fragment>
      ),
      toastOptions,
    ),
  rootEl: (
    <Toaster
      position="bottom-right"
      toastOptions={{
        blank: {
          ariaProps: {
            "aria-live": "polite",
            role: "status",
          },
          className: "border-opacity-30",
          style: {
            backgroundColor: "#3cb4e5",
            border: "1px solid white",
            color: "white",
            margin: "0 1.25rem",
            maxWidth: "42rem",
            padding: "1rem 0",
          },
        },
        duration: 10000,
        error: {
          ariaProps: {
            "aria-live": "assertive",
            role: "alert",
          },
          icon: null,
          style: {
            backgroundColor: "#e1251b",
            color: "white",
            margin: "0 1.25rem",
            maxWidth: "42rem",
            padding: "1rem 0",
          },
        },
        success: {
          ariaProps: {
            "aria-live": "polite",
            role: "status",
          },
          icon: null,
          style: {
            backgroundColor: "#00ae42",
            color: "white",
            margin: "0 1.25rem",
            maxWidth: "42rem",
            padding: "1rem 0",
          },
        },
      }}
    />
  ),
  success: (
    message: string,
    {
      closable = true,
      ...toastOptions
    }: { closable?: boolean } & ToastOptions = {},
  ) =>
    toast.success(
      (t) => (
        <Fragment>
          {message}
          {closable ? (
            <button
              type="button"
              className="ml-4 inline-flex rounded-md text-white text-opacity-90 transition-colors hover:text-opacity-100"
              onClick={() => toast.dismiss(t.id)}
            >
              <X className="h-5 w-5" aria-label="Dismiss" />
            </button>
          ) : null}
        </Fragment>
      ),
      toastOptions,
    ),
};
